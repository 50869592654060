
export default {
  props: {
    confirmation: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    title() {
      const name = this.formData?.prenom;
      const message = name
        ? this.$i18n.t('forms.confirmation.named', {
            name: name,
          })
        : this.$i18n.t('forms.confirmation.generic');
      const title = this.confirmation.title || message;

      return title;
    },
    gclidField() {
      const urlParams = new URLSearchParams(window.location.search);
      const rcd_gclid = urlParams.get('gclid');
      if (rcd_gclid)
        return {
          gclid: rcd_gclid,
        };

      return null;
    },
    customFormData() {
      if (process.client) {
        return this.gclidField
          ? { ...this.formData, ...this.gclidField }
          : this.formData;
      }
      return this.formData;
    },
  },
  methods: {
    handleBack() {
      this.$emit('back');
    },
  },
};
