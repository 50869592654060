import merlin from './merlin/submit';
import gamer from './gamer/submit';
import mailto from './mailto/submit';
import azzur from './azzur/submit';
import pipedrive from './pipedrive/submit';
import mri from './mri/submit';
import marketplace from './marketplace/submit';

export default (api) => {
  const type = api.toLowerCase();

  if (type === 'merlin') {
    return merlin;
  }

  if (type === 'mailto') {
    return mailto;
  }

  if (type === 'gamer') {
    return gamer;
  }

  if (type === 'marketplace') {
    return marketplace;
  }

  if (type === 'azzur') {
    return azzur;
  }
  if (type === 'pipedrive') {
    return pipedrive;
  }
  if (type === 'mri') {
    return mri;
  }

  if (process.env.NODE_ENV !== 'production') {
    console.warn(`No API found matching ${type}.`);
  }
  return null;
};
