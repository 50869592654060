
import { commonProps } from '../../utils/prismic';
import FormBlock from '../form-block';
import ConfirmationBlock from '../confirmation-block';

export default {
  components: { FormBlock, ConfirmationBlock },
  props: commonProps,
  data() {
    return {
      isSuccessful: false,
    };
  },
  methods: {
    toggleForm() {
      this.$refs.questionForm.handleToggle();
    },
    handleSuccess() {
      this.isSuccessful = true;
    },
  },
};
