import mergeI18n from '~/utils/merge-i18n';
import forms from '~/i18n/messages/fr-fr.forms.json';

const dataI18n = Object.assign({}, forms);

// Get HTML body of email
export default function getMailBody(data, context, buyapowa) {
  mergeI18n(context.$i18n, dataI18n);
  const hostname = window.location.hostname;
  const content = [];
  const href = window.location.href;

  const introKey = `mailto.${data.type}.intro`;
  const intro = context.$te(introKey) && context.$t(introKey, [hostname, href]);
  const isBuyapowa = data.type === 'parrainage' && buyapowa;
  const isClaim = data.type.includes('claim');

  if (intro) {
    content.push(`<p>${intro}</p>`);
  }

  if (isBuyapowa) {
    content.push(
      `<p>${context.$t('mailto.parrainage.information', [
        buyapowa?.last_name || '',
        buyapowa?.first_name || '',
      ])}</p>`
    );
  }

  // Interest
  if (data.type === 'pre-sale') {
    const interest = [];
    if (data.market) {
      interest.push(
        `<li>${context.$t('mailto.default.interest.entries.market', [
          data.market,
        ])}</li>`
      );
    }
    if (data.product) {
      interest.push(
        `<li>${context.$t('mailto.default.interest.entries.product', [
          data.product,
        ])}</li>`
      );
    }

    if (interest.length) {
      content.push(
        `<p><b>${context.$t('mailto.default.interest.title')}</b></p>`,
        `<ul style="padding: 0" align="left" type="disc">
        ${interest.join('')}
        </ul>`
      );
    }
  }

  if (data.type === 'question') {
    const date = new Intl.DateTimeFormat('fr-FR').format(new Date());

    content.push(
      `<li>${context.$t('mailto.default.interest.entries.market', [
        data.market,
      ])}</li>`
    );

    content.push(
      `<li>${context.$t('mailto.default.contact.entries.messageDate', [
        date,
      ])}</li>`
    );
  }

  // Contact Info
  const contacts = [];
  for (const [key, value] of Object.entries(data)) {
    const entryKey = isClaim && key === 'message' ? 'comment' : key;
    const localeKey = `mailto.default.contact.entries.${entryKey}`;

    if (context.$te(localeKey)) {
      let sanitized = value;
      if (key === 'contact' || key === 'subject' || key === 'claim') {
        sanitized = value.split('|')[0];
      }
      contacts.push(
        `<li>${context.$t(localeKey, [
          sanitized.replaceAll('\n', '<br />'),
        ])}</li>`
      );
    }
  }

  if (isBuyapowa && buyapowa.client_data?.account_number) {
    contacts.push(
      `<li>${context.$t('mailto.default.contact.entries.contratParrain', [
        buyapowa.client_data?.account_number,
      ])}</li>`
    );
  }

  if (contacts.length) {
    const contentLabel = context.$t(
      `mailto.default.contact.${isClaim ? 'title-claim' : 'title'}`
    );
    content.push(
      !isBuyapowa ? `<p><b>${contentLabel}</b></p>` : '',
      `<ul style="padding: 0" align="left" type="disc">
      ${contacts.join('')}
      </ul>`
    );
  }

  if (data.type === 'parrainage') {
    content.push(context.$t('mailto.parrainage.footer'));
    content.push(context.$t('mailto.parrainage.legals'));
  } else if (!isClaim) {
    content.push(`<p>${context.$t('mailto.default.footer')}</p>`);
  }

  return content.join('');
}
