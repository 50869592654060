
import submitApi from './api/submit';
import { pushCtaClick } from '~/plugins/gtm';
import { buyapowaMixin } from './api/buyapowa/index';
import mergeI18n from '~/utils/merge-i18n';
import formsI18n from '~/i18n/messages/fr-fr.forms.json';
import gamerI18n from '~/i18n/messages/fr-fr.gamer.json';

export default {
  mixins: [buyapowaMixin],
  props: {
    form: {
      type: Object,
      required: true,
    },
    narrow: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    emailSubject: {
      type: String,
      default: null,
    },
    withSteps: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiType: this.type,
      error: null,
      loading: false,
      submitted: false,
      i18n: {
        error: this.$t('forms.error.generic'),
      },
    };
  },
  computed: {
    activeScrollToFirstError() {
      return !this.buyapowa;
    },
  },
  beforeMount() {
    mergeI18n(
      this.$i18n,
      this.type === 'gamer'
        ? Object.assign({}, gamerI18n, formsI18n)
        : formsI18n
    );
  },
  mounted() {
    this.i18n.error = this.$t('forms.error.generic');

    if (this.withSteps) {
      const title = this.form?.steps[0]?.intro?.title;
      if (title) this.handleStepUpdate({ intro: { title } });
    }
  },
  methods: {
    handleError() {
      this.loading = false;
      this.error = this.i18n.error;

      this.$emit('error');
    },
    handleSuccess(response, submittedFormData) {
      this.loading = false;
      this.submitted = true;

      if (!this.withSteps) {
        const email =
          this.$el.querySelector('input[type=email]')?.value ?? null;
        const birthday =
          this.$el.querySelector('input#birthday')?.value ?? null;
        const euroAmount =
          this.$el.querySelector('input#emprunt')?.value ?? null;
        const marketCheckboxesChecked =
          this.$el.querySelectorAll('input[name=contratFilleul]:checked') ?? [];

        const marketTarget =
          [...marketCheckboxesChecked]
            .map((checkbox) => checkbox.value)
            .join(' ') ?? '';
        const formType =
          this.$el.querySelector('input[type=hidden]')?.value ?? null;

        pushCtaClick(this, {
          module: 'form-block-submit',
          email,
          birthday,
          euroAmount: euroAmount
            ? parseInt(euroAmount.replace(/ /g, ''), 10)
            : null,
          marketTarget,
          type: formType,
        });

        const data = new FormData(this.$el);
        this.$emit('success', {
          response,
          formData: data,
          formType: this.apiType,
        });
      } else {
        const { formData, data } = response;

        if (formData) {
          pushCtaClick(this, {
            module: 'form_steps',
            email: formData.get('email'),
            birthday: formData.get('birthDate'),
            euroAmount: formData.get('euroAmount')
              ? parseInt(formData.get('euroAmount').replace(/ /g, ''), 10)
              : null,
            type: formData.get('Provider'),
          });
        } else if (submittedFormData) {
          pushCtaClick(this, {
            module: 'form_steps',
            email: submittedFormData.get('email'),
            type: 'pipedrive',
          });
        } else {
          const emails = data?.person_id?.email || [];
          pushCtaClick(this, {
            module: 'form_steps',
            email: emails[0]?.value || null,
            type: 'pipedrive',
          });
        }

        this.$emit('success', {
          response,
          formData: formData ? formData : data,
          formType: this.apiType,
        });
      }
    },
    async handleFormStepSubmit(formData) {
      this.error = null;
      this.loading = true;

      const submit = submitApi(this.apiType);
      if (!submit) {
        this.handleError();
        return;
      }

      const cmpid = this.$cookies.get('cmpid');
      const originCmpid = cmpid ? `_${cmpid}` : '';
      let referrer = this.$cookies.get('__april_ref');
      referrer = referrer
        ? decodeURIComponent(referrer)
        : `${window.location.origin}${this.$route.fullPath}`;
      const origin = `${referrer}${originCmpid}`;

      submit(
        {
          formData,
          origin,
          subject: this.emailSubject,
          buyapowa: this.buyapowa,
          withSteps: true,
          stepsExtra: this.form.hiddenFields,
        },
        this
      )
        .then((res, submittedFormData) => {
          this.handleSuccess(res, submittedFormData);
        })
        .catch(this.handleError.bind(this));
    },
    async handleSubmit(formData) {
      this.error = null;
      this.loading = true;

      if (this.type === 'gamer') {
        const staff = this.$el.querySelector('input#staff')?.value || null;
        if (staff > 19) {
          this.apiType = 'mailto';
        }
      }

      const submit = submitApi(this.apiType);
      if (!submit) {
        this.handleError();
        return;
      }

      const cmpid = this.$cookies.get('cmpid');
      const originCmpid = cmpid ? `_${cmpid}` : '';
      let referrer = this.$cookies.get('__april_ref');
      referrer = referrer
        ? decodeURIComponent(referrer)
        : `${window.location.origin}${this.$route.fullPath}`;
      const origin = `${referrer}${originCmpid}`;

      submit(
        {
          formData,
          origin,
          subject: this.emailSubject,
          buyapowa: this.buyapowa,
        },
        this
      )
        .then(this.handleSuccess.bind(this))
        .catch(this.handleError.bind(this));
    },
    handleStepUpdate(data) {
      const title = data.intro.title;

      pushCtaClick(this, {
        module: 'form_steps_update',
        label: title,
      });
    },
  },
};
