export const azzurAdapter = (formData, type) => {
  const formDataObject = {};

  for (const [key, value] of formData.entries()) {
    formDataObject[key] = value;
  }

  const {
    activitesExercees,
    chiffreAffaires,
    dateCreationEntreprise,
    email,
    name,
    nom,
    telMobile,
    zipcode,
    azzur_id_prov,
    azzur_id_agence,
    azzur_rcd_pm,
    azzur_auto_pm,
    civility,
    civ,
    firstname,
    phone,
    prenom,
    rcd_deja_assure,
  } = formDataObject || {};

  const data = {
    mail: email,
    nom: nom || name,
    port: telMobile,
    cp: zipcode,
    id_prov: azzur_id_prov,
    id_agence: azzur_id_agence,
    civ: civ || civility || '1',
    prenom: firstname || prenom,
    tel: phone,
  };

  if (type === 'rcd') {
    data.rcd_act_a = activitesExercees;
    data.rcd_ca_en_cours = chiffreAffaires
      ? chiffreAffaires.replace(/\s/g, '')
      : null;
    data.rcd_date_creation = dateCreationEntreprise
      ? dateCreationEntreprise.split('/').reverse().join('-')
      : null;
    data.rcd_pm = azzur_rcd_pm;
    data.rcd_deja_assure = rcd_deja_assure;
  } else if (type === 'auto') {
    data.aut_pm = azzur_auto_pm;
  }

  return data;
};
