import jsesc from 'jsesc';

export default ({ formData, origin }, context) => {
  const api = '/api/gamer';

  return new Promise((resolve, reject) => {
    const data = {};
    const tracking = {
      Origine: origin,
      OrigineVisiteur: origin,
      lead: {
        pageOrigine: context.$route.fullPath,
        siteInternetOrigine: origin,
      },
    };
    let idaction;

    for (const [key, value] of formData.entries()) {
      key === 'idaction' ? (idaction = value) : (data[key] = value);
    }

    const raw = jsesc(
      {
        key: context.$config.gamer_api_key,
        idaction,
        ...tracking,
        data,
      },
      {
        json: true,
      }
    );

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: raw,
    };

    fetch(api, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (!response.success) {
          reject(response);
        } else {
          resolve(response);
        }
      })
      .catch(reject);
  });
};
