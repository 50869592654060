import { render, staticRenderFns } from "./index.vue?vue&type=template&id=69a77522&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsFormStepsCreditor: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/organisms/form-steps-creditor/form-steps-creditor.vue').default,OrganismsFormBlock: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/organisms/form-block/form-block.vue').default})
