import { mriAdapter } from './adapter';

const sendForm = async (form) => {
  const path = '/api/mri';
  const body = { ...form };

  try {
    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      return await response.json();
    }

    throw new Error(response);
  } catch (error) {
    throw new Error(error);
  }
};

export default async ({ formData }) => {
  const form = await mriAdapter(formData);

  const result = await sendForm(form);
  return result;
};
